import React, { useCallback, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { FieldArrayWithId, useFieldArray, UseFieldArrayRemove, UseFieldArrayUpdate, UseFormRegister, UseFormReturn } from "react-hook-form"
import FileUploadField from "../../components/atoms/file-upload-field"
import TrashIcon from "../../components/fundamentals/icons/trash-icon"
import Actionables, {
    ActionType,
} from "../../components/molecules/actionables"
import { DragItem, FormImage } from "../../types/shared"
import type { Identifier, XYCoord } from "dnd-core"
import clsx from "clsx"
import GripIcon from "../../components/fundamentals/icons/grip-icon"
import PlusIcon from "../../components/fundamentals/icons/plus-icon"
import Button from "../../components/fundamentals/button"
import { SectionForm } from "./detail-thumbnail-modal"
import Input from "../../components/molecules/input"

type ImageCardProps = {
    field: FieldArrayWithId<SectionForm, "thumbnail", "id">,
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void,
    removeSection: UseFieldArrayRemove
    updateSection: UseFieldArrayUpdate<SectionForm, "thumbnail">
    register: UseFormRegister<SectionForm>
    // append: (value: FormImage | FormImage[], options?: FieldArrayMethodProps | undefined) => void
}

const ImageCard = ({
    field,
    index,
    moveCard,
    removeSection,
    updateSection,
    register
    // append
}: ImageCardProps) => {
    const [isNeedMobile, setIsNeedMobile] = useState(false);

    const showMobile = () => {
        setIsNeedMobile(true);
    }

    const hasUploadedPcImage = field.pc_image !== null;
    const hasUploadedMobileImage = field.mobile_image !== null;

    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: "card",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // console.log(dragIndex);
            // console.log(hoverIndex);
            // console.log(hoverClientY);
            // console.log(hoverMiddleY);

            // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            //     return
            // }

            // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            //     return
            // }

            moveCard(dragIndex, hoverIndex)

            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: "card",
        item: () => {
            return { index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(ref))

    const handleFilesChosen = (files: File[], isForMobile = false) => {
        const file = files[0];  // Limit one uploading file

        if (!file) {
            return
        }

        const toAppendImage = {
            url: URL.createObjectURL(file),
            name: file.name,
            size: file.size,
            nativeFile: file,
            selected: false,
        }

        const toUpdateSection: ThumbnailFormType = isForMobile ? {
            ...field,
            mobile_image: toAppendImage
        } : {
            ...field,
            pc_image: toAppendImage
        }

        updateSection(index, toUpdateSection)
    }

    return (
        <div
            ref={preview}
            data-handler-id={handlerId}
            className={clsx(
                "grid grid-cols transition-all rounded-rounded hover:bg-grey-5 focus-within:bg-grey-5 h-25 py-xsmall pl-xsmall pr-base translate-y-0 translate-x-0",
                {
                    "bg-grey-5 opacity-50": isDragging,
                }
            )}
        >
            <h2 className="inter-large-semibold mb-small" >Carousel Image Info #{index + 1}</h2>
            <div className="flex flex-row cursor-move" ref={ref}>
                <div className="text-grey-40 self-center"><GripIcon size={20} /></div>
                {
                    <div className="flex flex-1 flex-col ml-3">
                        <div className="flex flex-row justify-between gap-x-3">
                            {
                                !hasUploadedPcImage ?
                                    <FileUploadField
                                        onFileChosen={(files) => {
                                            handleFilesChosen(files)
                                        }}
                                        placeholder={
                                            <div className="text-center">
                                                <b>For PC size</b>
                                                <p>1200 x 1600 (3:4) recommended</p>
                                                <p>up to 10MB each</p>
                                            </div>
                                        }
                                        filetypes={["image/gif", "image/jpeg", "image/png", "image/webp"]}
                                        className="py-large my-base"
                                        multiple={false}
                                    />
                                    :
                                    <Image
                                        isForMobile={false}
                                        key={field.id}
                                        image={field.pc_image}
                                        actions={
                                            [
                                                {
                                                    label: "Delete",
                                                    onClick: () => updateSection(index, {
                                                        ...field,
                                                        pc_image: null
                                                    }),
                                                    icon: <TrashIcon size={20} />,
                                                    variant: "danger",
                                                }
                                            ]
                                        }
                                    />
                            }
                            {
                                !hasUploadedMobileImage ?
                                    <FileUploadField

                                        onFileChosen={(files) => {
                                            handleFilesChosen(files, true)
                                        }}
                                        placeholder={
                                            <div className="text-center">
                                                <b>For Mobile size</b>
                                                <p>1200 x 1600 (3:4) recommended</p>
                                                <p>up to 10MB each</p>
                                            </div>
                                        }
                                        filetypes={["image/gif", "image/jpeg", "image/png", "image/webp"]}
                                        className="py-large my-base"
                                        multiple={false}
                                    />
                                    :
                                    <Image
                                        isForMobile={true}
                                        key={field.id}
                                        image={field.mobile_image}
                                        actions={
                                            [
                                                {
                                                    label: "Delete",
                                                    onClick: () => updateSection(index, {
                                                        ...field,
                                                        mobile_image: null
                                                    }),
                                                    icon: <TrashIcon size={20} />,
                                                    variant: "danger",
                                                }
                                            ]
                                        }
                                    />
                            }
                        </div>
                        <div className="grid grid-rows-2 grid-flow-col gap-x-3 mt-base">
                            <Input
                                className="mt-base"
                                label="Title"
                                {...register(`thumbnail.${index}.title`)}
                                placeholder="Welcome to my store"
                            />
                            <Input
                                className="mt-base"
                                label="Description"
                                {...register(`thumbnail.${index}.description`)}
                                placeholder="Great shop"
                            />
                            <Input
                                className="mt-base"
                                label="Button Text"
                                {...register(`thumbnail.${index}.button.text`)}
                                placeholder="Let's go shopping"
                            />
                            <Input
                                className="mt-base"
                                label="Button Redirect To"
                                {...register(`thumbnail.${index}.button.href`)}
                                placeholder="https://..."
                            />
                        </div>
                        <Button
                            variant="danger"
                            size="small"
                            className="h-10 mt-8 w-full"
                            type="button"
                            onClick={() => removeSection(index)}
                        >
                            <TrashIcon size={20} />
                            <span>Delete Section</span>
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}

export type ThumbnailFormType = {
    pc_image: FormImage | null
    mobile_image: FormImage | null
    title: string
    description: string
    button: {
        text: string,
        href: string
    }
}


type Props = {
    form: UseFormReturn<SectionForm, any>
}

const ThumbnailForm = ({ form }: Props) => {
    const { control, register } = form

    const {
        fields,
        remove: removeSection,
        update: updateSection,
        replace,
        append: appendNewSection,
        move
    } = useFieldArray({
        control: control,
        name: "thumbnail",
    })

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        move(dragIndex, hoverIndex)
    }, [])

    const renderCard = useCallback(
        (
            field: FieldArrayWithId<SectionForm, "thumbnail", "id">,
            index: number
        ) => {
            return (
                <ImageCard
                    key={field.id}
                    field={field}
                    index={index}
                    moveCard={moveCard}
                    removeSection={removeSection}
                    updateSection={updateSection}
                    register={register}
                />
            )
        },
        [fields]
    )

    return (
        <div>
            {
                fields.length > 0 ? fields.map((field, idx) => {
                    return <div className="mt-large">
                        <div className="flex flex-col gap-y-2xsmall">
                            <div>{renderCard(field, idx)}</div>
                        </div>
                    </div>
                }) : null
            }
            <Button
                variant="secondary"
                size="small"
                className="h-10 w-full mt-base"
                type="button"
                onClick={() => appendNewSection({
                    pc_image: null,
                    mobile_image: null,
                    title: "",
                    description: "",
                    button: {
                        text: "",
                        href: ""
                    }
                })}
            >
                <PlusIcon size={20} />
                <span>Add a section</span>
            </Button>
        </div>
    )
}

type ThumbnailProps = {
    isForMobile: boolean
    image: FormImage | null
    actions: ActionType[]
}

const Image = ({ isForMobile, image, actions }: ThumbnailProps) => {
    if (!image) {
        return null
    }

    return (
        <div className="px-base py-xsmall group hover:bg-grey-5 rounded-rounded flex items-center justify-between my-base">
            <div className="flex items-center gap-x-large">
                <div className="mr-3">{isForMobile ? "For Mobile Image" : "For PC Image"}</div>
                <div className="w-16 h-16 flex items-center justify-center">
                    <img
                        src={image.url}
                        alt={image.name || "Uploaded image"}
                        className="max-w-[64px] max-h-[64px] rounded-rounded"
                    />
                </div>
                <div className="flex flex-col inter-small-regular text-left">
                    <p>{image.name}</p>
                    <p className="text-grey-50">
                        {image.size ? `${(image.size / 1024).toFixed(2)} KB` : ""}
                    </p>
                </div>
            </div>
            <Actionables actions={actions} forceDropdown />
        </div>
    )
}

export default ThumbnailForm
