import React, { useState } from "react"
import { Controller } from "react-hook-form"
import Switch from "../../../../components/atoms/switch"
import { NestedForm } from "../../../../utils/nested-form"

export type NeedLogisticsFormType = {
    value: boolean
}

export type NeedLogisticsProps = {
    form: NestedForm<NeedLogisticsFormType>
}

export const defaultIsNeedLogistics = true;

const NeedLogisticsForm = ({
    form,
}: NeedLogisticsProps) => {
    const { control, path } = form

    const [isNeedLogistics, setIsNeedLogistics] = useState(defaultIsNeedLogistics)

    return (
        <div>
            <div className="flex items-center justify-between mb-2xsmall">
                <h2 className="inter-base-semibold">需要物流（Need Logistics）</h2>
                <Controller
                    control={control}
                    name={path("value")}
                    render={({ field: { value, onChange } }) => {
                        setIsNeedLogistics(value)
                        return <Switch checked={value} onCheckedChange={onChange} />
                    }}
                />
            </div>
            <p className="inter-base-regular text-grey-50">
                When unchecked, this product will not has logistics.
            </p>
        </div>
    )
}

export default NeedLogisticsForm
