import { ProductCollection } from "../../../types/customize"  // * Customize interface
import {
  useAdminCreateCollection,
  useAdminUpdateCollection,
} from "medusa-react"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import Button from "../../fundamentals/button"
import IconTooltip from "../../molecules/icon-tooltip"
import InputField from "../../molecules/input"
import Modal from "../../molecules/modal"
import Metadata, { MetadataField } from "../../organisms/metadata"
import TagInput from "../../molecules/tag-input"
import Spinner from "../../atoms/spinner"
import FileUploadModal from "../../organisms/file-upload-modal"
import clsx from "clsx"
import { prepareImages } from "../../../utils/images"
import { FormImage } from "../../../types/shared"
import CollectionImageUpload from "./collection_image_upload"
import TextArea from "../../molecules/textarea"

type CollectionModalProps = {
  onClose: () => void
  onSubmit: (values: any, metadata: MetadataField[]) => void
  isEdit?: boolean
  collection?: ProductCollection
}

type CollectionModalFormData = {
  title: string
  handle: string | undefined
  categories: string[]  // * Add custom field
  image_url: string     // * Add custom field
  description: string   // * Add custom field
  banner_image_url: string // * Add custom field
}

// * Categories transformer
const transformCategories = (categories: string[]) => {
  return categories.length === 0 ? "" : categories.join("&&&");
}

// * Metadata transformer
const transformMetaData = (metadata: MetadataField[]) => {
  return metadata.reduce((acc, next) => {
    return {
      ...acc,
      [next.key]: next.value,
    }
  }, {})
}

const CollectionModal: React.FC<CollectionModalProps> = ({
  onClose,
  isEdit = false,
  collection,
}) => {
  const { mutate: update, isLoading: updating } = useAdminUpdateCollection(
    collection?.id!
  )
  const { mutate: create, isLoading: creating } = useAdminCreateCollection()

  const { register, handleSubmit, reset, control, getValues } = useForm<CollectionModalFormData>({
    defaultValues: {
      categories: []
    }
  })

  const notification = useNotification()
  const [metadata, setMetadata] = useState<MetadataField[]>([])

  // icon
  const [iconModalIsOpen, setIconModalIsOpen] = useState(false)
  const [isIconImgDeleting, setIsIconImgDeleting] = useState(false)
  const [isLoadingIcon, setIsLoadingIcon] = useState(false)

  // banner
  const [bannerModalIsOpen, setBannerModalIsOpen] = useState(false)
  const [isBannerImgDeleting, setIsBannerImgDeleting] = useState(false)
  const [isLoadingBanner, setIsLoadingBanner] = useState(false)

  if (isEdit && !collection) {
    throw new Error("Collection is required for edit")
  }

  useEffect(() => {
    register("title", { required: true })
    register("handle")
  }, [])

  useEffect(() => {
    if (isEdit && collection) {
      reset({
        title: collection.title,
        handle: collection.handle,
        categories: collection.categories.split("|")  // * Add custom field
      })

      if (collection.metadata) {
        Object.entries(collection.metadata).map(([key, value]) => {
          if (typeof value === "string") {
            const newMeta = metadata
            newMeta.push({ key, value })
            setMetadata(newMeta)
          }
        })
      }
    }
  }, [collection, isEdit])

  const submit = (data: CollectionModalFormData) => {
    if (isEdit) {
      update(
        {
          title: data.title,
          handle: data.handle,
          // @ts-ignore
          categories: transformCategories(data.categories), // * Add custom field, this will return string
          metadata: transformMetaData(metadata),
          description: data.description
        },
        {
          onSuccess: () => {
            notification(
              "Success",
              "Successfully updated collection",
              "success"
            )
            onClose()
          },
          onError: (error) => {
            notification("Error", getErrorMessage(error), "error")
          },
        }
      )
    } else {
      create(
        {
          title: data.title,
          handle: data.handle,
          // @ts-ignore
          categories: transformCategories(data.categories), // * Add custom field, this will return string
          metadata: transformMetaData(metadata),
          description: data.description
        },
        {
          onSuccess: () => {
            notification(
              "Success",
              "Successfully created collection",
              "success"
            )
            onClose()
          },
          onError: (error) => {
            notification("Error", getErrorMessage(error), "error")
          },
        }
      )
    }
  }

  // icon
  const handleIconFileUpload = async (files) => {
    setIconModalIsOpen(false)
    setIsLoadingIcon(true)

    const toAppend = files.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      nativeFile: file,
      selected: false,
    }))

    let preppedImages: FormImage[] = []
    try {
      preppedImages = await prepareImages(toAppend)
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the images."
      const response = (error as any).response as Response

      if (response.status === 500) {
        errorMessage =
          errorMessage +
          " " +
          "You might not have a file service configured. Please contact your administrator"
      }

      notification("Error", errorMessage, "error")
      return
    }

    const url = preppedImages?.[0]?.url

    const fieldValues = getValues()

    if (isEdit) {
      update(
        {
          // @ts-ignore
          image_url: url
        },
        {
          onSuccess: () => {
            notification(
              "Success",
              "Successfully update collection image",
              "success"
            )
          },
          onError: (error) => {
            notification("Error", getErrorMessage(error), "error")
          },
        }
      )
    } else {
      create(
        {
          // @ts-ignore
          image_url: url,
          title: fieldValues.title,
          handle: fieldValues.handle,
          categories: transformCategories(fieldValues.categories),
          metadata: transformMetaData(metadata)
        },
        {
          onSuccess: () => {
            notification(
              "Success",
              "Successfully upload collection image",
              "success"
            )
            onClose()
          },
          onError: (error) => {
            notification("Error", getErrorMessage(error), "error")
          },
        }
      )
    }

    setIsLoadingIcon(false)
  }
  const handleIconDelete = () => {
    setIsIconImgDeleting(true);
    update(
      {
        // @ts-ignore
        image_url: "",
      },
      {
        onSuccess: () => {
          notification("Success", "Successfully deleted collection image", "success")
        },
        onError: (err) => {
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
    setIsIconImgDeleting(false);
  }

  // banner
  const handleBannerFileUpload = async (files) => {
    setBannerModalIsOpen(false)
    setIsLoadingBanner(true)

    const toAppend = files.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      nativeFile: file,
      selected: false,
    }))

    let preppedImages: FormImage[] = []
    try {
      preppedImages = await prepareImages(toAppend)
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the images."
      const response = (error as any).response as Response

      if (response.status === 500) {
        errorMessage =
          errorMessage +
          " " +
          "You might not have a file service configured. Please contact your administrator"
      }

      notification("Error", errorMessage, "error")
      return
    }

    const url = preppedImages?.[0]?.url

    const fieldValues = getValues()

    if (isEdit) {
      update(
        {
          // @ts-ignore
          banner_image_url: url
        },
        {
          onSuccess: () => {
            notification(
              "Success",
              "Successfully update collection image",
              "success"
            )
          },
          onError: (error) => {
            notification("Error", getErrorMessage(error), "error")
          },
        }
      )
    } else {
      create(
        {
          // @ts-ignore
          banner_image_url: url,
          title: fieldValues.title,
          handle: fieldValues.handle,
          categories: transformCategories(fieldValues.categories),
          metadata: transformMetaData(metadata)
        },
        {
          onSuccess: () => {
            notification(
              "Success",
              "Successfully upload collection image",
              "success"
            )
            onClose()
          },
          onError: (error) => {
            notification("Error", getErrorMessage(error), "error")
          },
        }
      )
    }

    setIsLoadingBanner(false)
  }
  const handleBannerDelete = () => {
    setIsBannerImgDeleting(true);
    update(
      {
        // @ts-ignore
        banner_image_url: "",
      },
      {
        onSuccess: () => {
          notification("Success", "Successfully deleted collection image", "success")
        },
        onError: (err) => {
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
    setIsBannerImgDeleting(false);
  }

  return (
    <Modal handleClose={onClose} isLargeModal>
      <Modal.Body>
        <Modal.Header handleClose={onClose}>
          <div>
            <h1 className="inter-xlarge-semibold mb-2xsmall">
              {isEdit ? "編輯小組別(Edit Collection)" : "新增小組別(Add Collection)"}
            </h1>
            <p className="inter-small-regular text-grey-50">
              你可以在此新增一個「小組別」，基本只需要自訂名稱，這個小組別將可以囊括指定商品
              <br />To create a collection, all you need is a title and a handle.
            </p>
          </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(submit)}>
          <Modal.Content>
            <div>
              <h2 className="inter-base-semibold mb-base">Details</h2>
              <div className="flex items-center gap-x-base">
                <InputField
                  label="組別名稱(Title)"
                  required
                  placeholder="Sunglasses"
                  {...register("title", { required: true })}
                />
                <InputField
                  label="自訂網址(Handle)"
                  placeholder="sunglasses"
                  {...register("handle")}
                  prefix="/"
                  tooltip={
                    <IconTooltip content="URL Slug for the collection. Will be auto generated if left blank." />
                  }
                />
              </div>
            </div>
            <div className="mt-xlarge w-full">
              <div className="mb-5">
                <div className="flex items-baseline">
                  <h2 className="inter-base-semibold mb-base mr-5">首頁圓圈圖示(Icon Image)</h2>
                  <div className="flex justify-between">
                    {
                      collection?.image_url && (
                        <Button
                          variant="secondary"
                          size="small"
                          type="button"
                          onClick={handleIconDelete}
                          loading={isIconImgDeleting}
                        >
                          Delete
                        </Button>
                      )}
                  </div>
                </div>
                <div className="mt-base">
                  <div
                    onClick={() => setIconModalIsOpen(true)}
                    className={`${isLoadingIcon ? "pointer-events-none" : ""} w-28 h-28 p-2 mt-2 flex items-center justify-center rounded-rounded hover:bg-grey-5 cursor-pointer relative`}
                  >
                    {isLoadingIcon && (
                      <div className="z-10 absolute justify-center items-center">
                        <Spinner variant="secondary" />
                      </div>
                    )}
                    <div
                      className={clsx("w-full h-full transition-opacity", {
                        "opacity-50": isLoadingIcon,
                      })}
                    >
                      <CollectionImageUpload
                        color="bg-teal-40"
                        image={collection?.image_url ?? ""}
                        font="inter-3xlarge-semibold"
                      />
                    </div>
                  </div>
                  {iconModalIsOpen && (
                    <FileUploadModal
                      setFiles={handleIconFileUpload}
                      filetypes={["image/png", "image/jpeg"]}
                      handleClose={() => setIconModalIsOpen(false)}
                    />
                  )}
                </div>
              </div>
              <div className="mb-5">
                <div className="flex items-baseline">
                  <h2 className="inter-base-semibold mb-base mr-5">組別介紹頁用圖(Banner Image)</h2>
                  <div className="flex justify-between">
                    {
                      collection?.banner_image_url && (
                        <Button
                          variant="secondary"
                          size="small"
                          type="button"
                          onClick={handleBannerDelete}
                          loading={isBannerImgDeleting}
                        >
                          Delete
                        </Button>
                      )}
                  </div>
                </div>
                <div className="mt-base">
                  <div
                    onClick={() => setBannerModalIsOpen(true)}
                    className={`${isLoadingBanner ? "pointer-events-none" : ""} w-28 h-28 p-2 mt-2 flex items-center justify-center rounded-rounded hover:bg-grey-5 cursor-pointer relative`}
                  >
                    {isLoadingBanner && (
                      <div className="z-10 absolute justify-center items-center">
                        <Spinner variant="secondary" />
                      </div>
                    )}
                    <div
                      className={clsx("w-full h-full transition-opacity", {
                        "opacity-50": isLoadingBanner,
                      })}
                    >
                      <CollectionImageUpload
                        color="bg-teal-40"
                        image={collection?.banner_image_url ?? ""}
                        font="inter-3xlarge-semibold"
                      />
                    </div>
                  </div>
                  {bannerModalIsOpen && (
                    <FileUploadModal
                      setFiles={handleBannerFileUpload}
                      filetypes={["image/png", "image/jpeg"]}
                      handleClose={() => setBannerModalIsOpen(false)}
                    />
                  )}
                </div>
              </div>
              <div>
                <h2 className="inter-base-semibold mb-base">組別介紹(Description)</h2>
                <TextArea
                  label="Description"
                  placeholder="Write a short introduction to this collection..."
                  rows={8}
                  className="mb-small"
                  {...register("description")}
                  defaultValue={collection?.description}
                />
              </div>
              <div>
                <h2 className="inter-base-semibold mb-base">大目錄(Categories，請用(,)逗號分隔)</h2>
                <p className="inter-small-regular text-grey-50">
                  你可以在此新增「大目錄」，這個大目錄將會包含此「小組別(Collection)」，而大目錄也將顯示於導覽列中<br />
                  Which categories will this collection belongs to? <br />
                  Categories can contain multiple collections, and collecion can also belong to multiple categories.<br />
                  {/* <br />
                  If you want to keep the relationship of categories. use <b className="bg-grey-20">{'->'}</b> symbol to connect them.<br />
                  For example, the category <b>Phone</b> belongs to category <b>Daily Used</b>. You can use <b className="bg-grey-20">{'Daily Used->Phone'}</b> to describe their relationship.<br /> */}
                </p>
              </div>
              <div className="mt-xlarge w-full">
                <Controller
                  control={control}
                  name={"categories"}
                  render={({ field: { value, onChange } }) => {
                    // * Split `&&&`
                    const parsed_value = value.reduce((parsed_value: string[], v) => {
                      if (v.includes("&&&")) {
                        parsed_value = parsed_value.concat(v.split("&&&"))
                      } else {
                        if (v !== "") {
                          parsed_value.push(v)
                        }
                      }
                      return parsed_value
                    }, [])
                    return (
                      <TagInput
                        className="w-full"
                        onValidate={(newVal) => {
                          if (value.includes(newVal)) {
                            return null
                          }
                          return newVal
                        }}
                        invalidMessage="already exists"
                        showLabel={false}
                        values={parsed_value}
                        onChange={onChange}
                        placeholder="Daily Used"
                      />
                    )
                  }}
                />
              </div>
            </div>
            <div className="mt-xlarge w-full">
              <Metadata setMetadata={setMetadata} metadata={metadata} />
            </div>
          </Modal.Content>
          <Modal.Footer>
            <div className="flex items-center justify-end w-full gap-x-xsmall">
              <Button
                variant="secondary"
                size="small"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="small"
                loading={isEdit ? updating : creating}
              >
                {`${isEdit ? "Save" : "Publish"} collection`}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CollectionModal
