import { Store as MedusaStore } from "@medusajs/medusa"
import clsx from "clsx"
import { useAdminStore, useAdminUpdateStore } from "medusa-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Spinner from "../../components/atoms/spinner"
import Button from "../../components/fundamentals/button"
import BreadCrumb from "../../components/molecules/breadcrumb"
import Input from "../../components/molecules/input"
import BodyCard from "../../components/organisms/body-card"
import FileUploadModal from "../../components/organisms/file-upload-modal"
import useNotification from "../../hooks/use-notification"
import { FormImage } from "../../types/shared"
import { getErrorMessage } from "../../utils/error-messages"
import { CarouselPayload, prepareImages } from "../../utils/images"
import LogoImage from "./detail-logo"
import ThumbnailSection from "./detail-thumbnail"
import useEditStoreActions from "./detail_hooks"

// * Add custom fields
export interface ExtendedStore extends MedusaStore {
  logo: string
  images: CarouselPayload[]
}

type AccountDetailsFormData = {
  name: string
  swap_link_template: string | undefined
  payment_link_template: string | undefined
  invite_link_template: string | undefined
}

const AccountDetails = () => {
  const { register, reset, handleSubmit } = useForm<AccountDetailsFormData>()
  const res = useAdminStore()
  const { mutate } = useAdminUpdateStore()

  const [isDeleting, setIsDeleting] = useState(false)

  const { onUpdate } = useEditStoreActions()

  const notification = useNotification()

  const store = res.store as ExtendedStore

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false)

  const handleCancel = () => {
    if (store) {
      reset(mapStoreDetails(store))
    }
  }

  useEffect(() => {
    handleCancel()
  }, [store])

  const onSubmit = (data: AccountDetailsFormData) => {
    const validateSwapLinkTemplate = validateUrl(data.swap_link_template)
    const validatePaymentLinkTemplate = validateUrl(data.payment_link_template)
    const validateInviteLinkTemplate = validateUrl(data.invite_link_template)

    if (!validateSwapLinkTemplate) {
      notification("Error", "Malformed swap url", "error")
      return
    }

    if (!validatePaymentLinkTemplate) {
      notification("Error", "Malformed payment url", "error")
      return
    }

    if (!validateInviteLinkTemplate) {
      notification("Error", "Malformed invite url", "error")
      return
    }

    mutate(data, {
      onSuccess: () => {
        notification("Success", "Successfully updated store", "success")
      },
      onError: (error) => {
        notification("Error", getErrorMessage(error), "error")
      },
    })
  }

  const handleFileUpload = async (files) => {
    setModalIsOpen(false)
    setIsLoadingProfilePicture(true)

    const toAppend = files.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      nativeFile: file,
      selected: false,
    }))

    let preppedImages: FormImage[] = []
    try {
      preppedImages = await prepareImages(toAppend)
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the images."
      const response = (error as any).response as Response

      if (response.status === 500) {
        errorMessage =
          errorMessage +
          " " +
          "You might not have a file service configured. Please contact your administrator"
      }

      notification("Error", errorMessage, "error")
      return
    }

    const url = preppedImages?.[0]?.url

    onUpdate(
      {
        // @ts-ignore
        logo: url || null,
      },
      () => {
        console.log("Upload logo image OK")
      }
    )

    setIsLoadingProfilePicture(false)
  }

  // Handle delete logo image
  const handleDelete = () => {
    setIsDeleting(true);
    onUpdate(
      {
        // @ts-ignore
        logo: "",
      },
      {
        onSuccess: () => {
          notification("Success", "Successfully deleted logo", "success")
        },
        onError: (err) => {
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
    setIsDeleting(false);
  }

  return (
    <form className="flex-col py-5">
      <div className="max-w-[632px]">
        <BreadCrumb
          previousRoute="/a/settings/"
          previousBreadcrumb="Settings"
          currentPage="Store Details"
        />
        <BodyCard
          events={[
            {
              label: "Save",
              type: "button",
              onClick: handleSubmit(onSubmit),
            },
            { label: "Cancel changes", type: "button", onClick: handleCancel },
          ]}
          title="商家資訊(Store Details)"
          subtitle="Manage your business details"
        >
          <h6 className="mt-large inter-base-semibold">General</h6>
          <Input
            className="mt-base"
            label="Store name"
            {...register("name")}
            placeholder="Store"
          />
          <div className="mt-base">
            <div className="flex justify-between">
              <span className="inter-base-semibold">Logo</span>
              {
                store?.logo && (
                  <Button
                    variant="secondary"
                    size="small"
                    type="button"
                    onClick={handleDelete}
                    loading={isDeleting}
                  >
                    Delete
                  </Button>
                )}
            </div>
            <div
              onClick={() => setModalIsOpen(true)}
              className={`${isLoadingProfilePicture ? "pointer-events-none" : ""} w-28 h-28 p-2 mt-2 flex items-center justify-center rounded-rounded hover:bg-grey-5 cursor-pointer relative`}
            >
              {isLoadingProfilePicture && (
                <div className="z-10 absolute justify-center items-center">
                  <Spinner variant="secondary" />
                </div>
              )}
              <div
                className={clsx("w-full h-full transition-opacity", {
                  "opacity-50": isLoadingProfilePicture,
                })}
              >
                <LogoImage
                  color="bg-teal-40"
                  image={store?.logo}
                  font="inter-3xlarge-semibold"
                />
              </div>
            </div>
            {modalIsOpen && (
              <FileUploadModal
                setFiles={handleFileUpload}
                filetypes={["image/png", "image/jpeg"]}
                handleClose={() => setModalIsOpen(false)}
              />
            )}
          </div>
          <div className="mt-base">
            <span className="inter-base-semibold">Shop Images</span>
            <ThumbnailSection store={store} />
          </div>
          <h6 className="mt-2xlarge inter-base-semibold">Advanced settings</h6>
          <Input
            className="mt-base"
            label="Swap link template"
            {...register("swap_link_template")}
            placeholder="https://acme.inc/swap={swap_id}"
          />
          <Input
            className="mt-base"
            label="Draft order link template"
            {...register("payment_link_template")}
            placeholder="https://acme.inc/payment={payment_id}"
          />
          <Input
            className="mt-base"
            label="Invite link template"
            {...register("invite_link_template")}
            placeholder="https://acme-admin.inc/invite?token={invite_token}"
          />
        </BodyCard>
      </div>
    </form>
  )
}

const validateUrl = (address: string | undefined) => {
  if (!address || address === "") {
    return true
  }

  try {
    const url = new URL(address)
    return url.protocol === "http:" || url.protocol === "https:"
  } catch (_) {
    return false
  }
}

const mapStoreDetails = (store: ExtendedStore): AccountDetailsFormData => {
  return {
    name: store.name,
    swap_link_template: store.swap_link_template ?? "",
    payment_link_template: store.payment_link_template ?? "",
    invite_link_template: store.invite_link_template ?? "",
  }
}

export default AccountDetails
