import React, { useState } from "react"
import { Controller } from "react-hook-form"
import Switch from "../../../../components/atoms/switch"
import { NestedForm } from "../../../../utils/nested-form"

export type PaymentSubscriptionFormType = {
    value: boolean
}

export type PaymentSubscriptionProps = {
    form: NestedForm<PaymentSubscriptionFormType>
}

export const defaultIsPaymentSubscription = false;

const PaymentSubscriptionForm = ({
    form,
}: PaymentSubscriptionProps) => {
    const { control, path } = form

    const [isPaymentSubscription, setIsPaymentSubscription] = useState(defaultIsPaymentSubscription)

    return (
        <div>
            <div className="flex items-center justify-between mb-2xsmall">
                <h2 className="inter-base-semibold">是訂閱性質商品（Payment Subscription）</h2>
                <Controller
                    control={control}
                    name={path("value")}
                    render={({ field: { value, onChange } }) => {
                        setIsPaymentSubscription(value)
                        return <Switch checked={value} onCheckedChange={onChange} />
                    }}
                />
            </div>
            <p className="inter-base-regular text-grey-50">
                When unchecked payment will be applied to onetime.
            </p>
        </div>
    )
}

export default PaymentSubscriptionForm
