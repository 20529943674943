import React from "react"
import { Controller, useFieldArray } from "react-hook-form"
import Button from "../../../../components/fundamentals/button"
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import InputField from "../../../../components/molecules/input"
import { NextSelect } from "../../../../components/molecules/select/next-select"
import TextArea from "../../../../components/molecules/textarea"
import { Option } from "../../../../types/shared"
import { countries } from "../../../../utils/countries"
import FormValidator from "../../../../utils/form-validator"
import { NestedForm } from "../../../../utils/nested-form"

// * Custom field
export type Attribute = {
  title: string
  value: any
}

export type CustomsFormType = {
  mid_code: string | null
  hs_code: string | null
  origin_country: Option | null
  // * Custom field
  other_attrs?: Attribute[]
}

type CustomsFormProps = {
  form: NestedForm<CustomsFormType>
  hasOtherAttrsForm: boolean
}

/**
 * Re-usable nested form used to submit customs information for products and their variants.
 * @example
 * <CustomsForm form={nestedForm(form, "customs")} />
 */
const CustomsForm = ({ form, hasOtherAttrsForm = false }: CustomsFormProps) => {
  const {
    register,
    path,
    control,
    formState: { errors },
  } = form

  const { fields, remove, append } = useFieldArray({
    name: path("other_attrs"),
    control,
    shouldUnregister: false,  // * Whether Field Array will be unregistered after unmount. Set it false to prevent attributes disappearing
  })

  const handleAddAnOption = () => {
    append({ title: "", value: "" })
  }

  const countryOptions = countries.map((c) => ({
    label: c.name,
    value: c.alpha2,
  }))

  return (
    <>
      <div className="grid grid-cols-2 gap-large pb-2xsmall">
        <InputField
          label="MID Code"
          placeholder="XDSKLAD9999..."
          {...register(path("mid_code"), {
            pattern: FormValidator.whiteSpaceRule("MID Code"),
          })}
          errors={errors}
        />
        <InputField
          label="HS Code"
          placeholder="BDJSK39277W..."
          {...register(path("hs_code"), {
            pattern: FormValidator.whiteSpaceRule("HS Code"),
          })}
          errors={errors}
        />
        <Controller
          name={path("origin_country")}
          control={control}
          render={({ field }) => {
            return (
              <NextSelect
                label="Country of origin"
                placeholder="Choose a country"
                options={countryOptions}
                isSearchable
                isClearable
                {...field}
              />
            )
          }}
        />
      </div>
      {
        hasOtherAttrsForm ? <div className="grid grid-cols-1 gap-large pb-2xsmall mt-base">
          <div className="flex flex-col gap-y-small">
            <p className="inter-small-semibold text-grey-50">Other attributes</p>
            <Button
              variant="secondary"
              className="w-full h-10"
              type="button"
              onClick={handleAddAnOption}
            >
              <PlusIcon size="20" /> Add an attribute
            </Button>
            <div className="flex flex-col gap-y-xsmall w-full">
              {fields.map((field, index) => {
                return (
                  <div
                    className="w-full flex items-center"
                    key={field.id}
                  >
                    <div className="m-auto">{index + 1}. </div>
                    <InputField
                      key={field.id + "_title"}
                      placeholder="title"
                      className="w-1/2 px-2"
                      {...register(path(`other_attrs.${index}.title`), {
                        required: "Option title is required",
                        minLength: FormValidator.minOneCharRule(
                          "Option title"
                        ),
                        pattern: FormValidator.whiteSpaceRule("Option title"),
                      })}
                      errors={errors}
                    />
                    <TextArea
                      label=""
                      key={field.id + "_value"}
                      placeholder="value"
                      className="w-1/2 px-2"
                      {...register(path(`other_attrs.${index}.value`), {
                        required: "Option title is required",
                        // minLength: FormValidator.minOneCharRule(
                        //   "Option title"
                        // ),
                        // pattern: FormValidator.whiteSpaceRule("Option title"),
                      })}
                      errors={errors}
                    />
                    {/* <InputField
                      key={field.id + "_value"}
                      placeholder="value"
                      className="w-1/2 px-2"
                      {...register(path(`other_attrs.${index}.value`), {
                        required: "Option title is required",
                        minLength: FormValidator.minOneCharRule(
                          "Option title"
                        ),
                        pattern: FormValidator.whiteSpaceRule("Option title"),
                      })}
                      errors={errors}
                    /> */}
                    <Button
                      variant="secondary"
                      className="px-2.5 py-2.5 max-h-[40px]"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <TrashIcon className="text-grey-40" size="20" />
                    </Button>
                  </div>
                )
              })}
            </div>
          </div>
        </div> : null
      }
    </>
  )
}

export default CustomsForm
