import { ThumbnailFormType } from "../domain/settings/detail-thumbnail-form";
import Medusa from "../services/api";
import { FormImage } from "../types/shared";

const splitImages = (
  images: FormImage[]
): { uploadImages: FormImage[]; existingImages: FormImage[] } => {
  const uploadImages: FormImage[] = []
  const existingImages: FormImage[] = []

  images.forEach((image) => {
    if (image.nativeFile) {
      uploadImages.push(image)
    } else {
      existingImages.push(image)
    }
  })

  return { uploadImages, existingImages }
}

export const prepareImages = async (images: FormImage[]) => {
  const { uploadImages, existingImages } = splitImages(images)

  let uploadedImgs: FormImage[] = []
  if (uploadImages.length > 0) {
    const files = uploadImages.map((i) => i.nativeFile)
    uploadedImgs = await Medusa.uploads
      .create(files)
      .then(({ data }) => data.uploads)
  }

  return [...existingImages, ...uploadedImgs]
}

// * Customize carousel images upload
export interface CarouselPayload {
  pc_image_url: string
  mobile_image_url: string
  title: string
  description: string
  button: {
    text: string
    href: string
  }
}
export const prepareCarouselPayloads = async (thumbnailForms: ThumbnailFormType[]): Promise<CarouselPayload[]> => {
  const carouselPayloads: CarouselPayload[] = []

  for (let i = 0; i < thumbnailForms.length; i++) {
    const each = thumbnailForms[i];

    let pc_image_url = "";
    let mobile_image_url = "";

    // mobile
    if (each.mobile_image?.nativeFile) {
      const uploadedImgs = await Medusa.uploads
        .create([each.mobile_image.nativeFile])  // Needs an array
        .then(({ data }) => data.uploads)
      mobile_image_url = uploadedImgs[0].url;
    } else {
      mobile_image_url = each.mobile_image?.url ?? "";
    }

    // PC
    if (each.pc_image?.nativeFile) {
      const uploadedImgs = await Medusa.uploads
        .create([each.pc_image.nativeFile])  // Needs an array
        .then(({ data }) => data.uploads)
      pc_image_url = uploadedImgs[0].url;
    } else {
      pc_image_url = each.pc_image?.url ?? "";
    }

    carouselPayloads.push({
      title: each.title,
      description: each.description,
      button: {
        text: each.button.text,
        href: each.button.href
      },
      pc_image_url,
      mobile_image_url
    })
  }

  return carouselPayloads;
}
