import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../components/fundamentals/button"
import Modal from "../../components/molecules/modal"
import useNotification from "../../hooks/use-notification"
import { CarouselPayload, prepareCarouselPayloads } from "../../utils/images"
import ThumbnailForm, {
    ThumbnailFormType,
} from "./detail-thumbnail-form"
import { ExtendedStore } from "./details"
import useEditStoreActions from "./detail_hooks"

type Props = {
    store: ExtendedStore
    open: boolean
    onClose: () => void
}

export type SectionForm = {
    thumbnail: ThumbnailFormType[]
}


const ThumbnailModal = ({ store, open, onClose }: Props) => {
    const [isLoading, setIsLoading] = useState(false)

    const { onUpdate, updating } = useEditStoreActions()
    const form = useForm<SectionForm>({
        defaultValues: getDefaultValues(store),
    })

    const {
        control,
        formState: { isDirty },
        handleSubmit,
        reset,
    } = form

    const notification = useNotification()

    useEffect(() => {
        reset(getDefaultValues(store))
    }, [store])

    const onReset = () => {
        reset(getDefaultValues(store))
        onClose()
        setIsLoading(false)
    }

    const onSubmit = handleSubmit(async (data) => {
        // TODO: ensure that all the fields are filled
        const isImagesComplete = data.thumbnail.every(s => s.mobile_image !== null || s.pc_image !== null)
        if (!isImagesComplete) {
            notification("Invalid image", "All the sections are required", "error")
            return;
        }

        setIsLoading(true)

        let preppedCarouselImages: CarouselPayload[] = []

        try {
            preppedCarouselImages = await prepareCarouselPayloads(data.thumbnail);
        } catch (error) {
            let errorMessage = "Something went wrong while trying to upload the images."
            const response = (error as any).response as Response

            if (response.status === 500) {
                errorMessage =
                    errorMessage +
                    " " +
                    "You might not have a file service configured. Please contact your administrator"
            }

            notification("Error", errorMessage, "error")
            return
        }

        onUpdate(
            {
                // @ts-ignore
                images: preppedCarouselImages || null,
            },
            onReset
        )
    })

    return (
        <Modal open={open} handleClose={onReset} isLargeModal>
            <Modal.Body>
                <Modal.Header handleClose={onReset}>
                    <h1 className="inter-xlarge-semibold m-0">Upload Images</h1>
                </Modal.Header>
                <form onSubmit={onSubmit}>
                    <Modal.Content>
                        <h2 className="inter-large-semibold mb-2xsmall">Images</h2>
                        <p className="inter-base-regular text-grey-50 mb-large">
                            Used to represent your store carousel images
                        </p>
                        <ThumbnailForm form={form} />
                    </Modal.Content>
                    <Modal.Footer>
                        <div className="flex gap-x-2 justify-end w-full">
                            <Button
                                size="small"
                                variant="secondary"
                                type="button"
                                onClick={onReset}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="primary"
                                type="submit"
                                disabled={!isDirty}
                                loading={updating || isLoading}
                            >
                                Save and close
                            </Button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export const getDefaultValues = (store: ExtendedStore): SectionForm => {
    return {
        thumbnail: store.images.map(image => ({
            title: image.title,
            description: image.description,
            mobile_image: {
                url: image.mobile_image_url
            },
            pc_image: {
                url: image.pc_image_url
            },
            button: {
                text: image.button.text,
                href: image.button.href
            }
        })),
    }
}

export default ThumbnailModal
