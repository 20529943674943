import { Product } from "@medusajs/medusa"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../../../components/fundamentals/button"
import Modal from "../../../../../components/molecules/modal"
import { countries } from "../../../../../utils/countries"
import { nestedForm } from "../../../../../utils/nested-form"
import CustomsForm, { Attribute, CustomsFormType } from "../../../components/customs-form"
import DimensionsForm, {
  DimensionsFormType,
} from "../../../components/dimensions-form"
import useEditProductActions from "../../hooks/use-edit-product-actions"
import _ from "lodash"
import useNotification from "../../../../../hooks/use-notification"

type Props = {
  product: Product
  open: boolean
  onClose: () => void
}

type AttributesForm = {
  dimensions: DimensionsFormType
  customs: CustomsFormType
}

// * Custom
export const filterMetaData = (existedMataData: Record<string, unknown>, newOtherAttrs: Attribute[]) => {
  // If the key in existed metadata doesn't included in new other attrs, then it is the title which will be removed
  const removedTitles = Object.keys(existedMataData).filter((title) => !newOtherAttrs.some(attr => attr.title === title))

  const metadata = newOtherAttrs.reduce((metadata, attrs) => {
    metadata[attrs.title] = attrs.value
    return metadata
  }, {})

  // Remain empty will delete it => Ref: https://github.com/medusajs/medusa/blob/9997485c55cdf5e634aa24a9505f02cb5fe4c010/packages/medusa/src/utils/set-metadata.ts#L25
  removedTitles.forEach(title => {
    metadata[title] = ""
  })

  return metadata
}


const AttributeModal = ({ product, open, onClose }: Props) => {
  const { onUpdate, updating } = useEditProductActions(product.id)
  const form = useForm<AttributesForm>({
    defaultValues: getDefaultValues(product),
  })
  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  useEffect(() => {
    reset(getDefaultValues(product))
  }, [product])

  const onReset = () => {
    reset(getDefaultValues(product))
    onClose()
  }

  const notification = useNotification()

  const onSubmit = handleSubmit((data) => {
    // Check if additional attributes have duplicate title
    const groups = _.groupBy(data.customs.other_attrs, "title")
    const attrHasDuplicate = Object.values(groups).some(g => g.length > 1)
    if (attrHasDuplicate) {
      notification("Error", "You cannot have duplicate attribute title!", "error")
      return;
    }

    onUpdate(
      {
        // @ts-ignore
        weight: data.dimensions.weight,
        // @ts-ignore
        width: data.dimensions.width,
        // @ts-ignore
        height: data.dimensions.height,
        // @ts-ignore
        length: data.dimensions.length,
        // @ts-ignore
        mid_code: data.customs.mid_code,
        // @ts-ignore
        hs_code: data.customs.hs_code,
        origin_country: data.customs.origin_country?.value,
        // * Custom field
        metadata: filterMetaData(product.metadata ?? {}, data.customs?.other_attrs ?? [])
      },
      onReset
    )
  })

  return (
    <Modal open={open} handleClose={onReset} isLargeModal>
      <Modal.Body>
        <Modal.Header handleClose={onReset}>
          <h1 className="inter-xlarge-semibold m-0">編輯商品規格(Edit Attributes)</h1>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Content>
            <div className="mb-xlarge">
              <h2 className="inter-large-semibold mb-2xsmall">尺寸(Dimensions)</h2>
              <p className="inter-base-regular text-grey-50 mb-large">
                Configure to calculate the most accurate shipping rates
              </p>
              <DimensionsForm form={nestedForm(form, "dimensions")} />
            </div>
            <div>
              <h2 className="inter-large-semibold mb-2xsmall">Customs</h2>
              <p className="inter-base-regular text-grey-50 mb-large">
                Configure to calculate the most accurate shipping rates
              </p>
              <CustomsForm form={nestedForm(form, "customs")} hasOtherAttrsForm={true} />
            </div>
          </Modal.Content>
          <Modal.Footer>
            <div className="flex gap-x-2 justify-end w-full">
              <Button
                size="small"
                variant="secondary"
                type="button"
                onClick={onReset}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="primary"
                type="submit"
                disabled={!isDirty}
                loading={updating}
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  )
}

const getDefaultValues = (product: Product): AttributesForm => {
  const country = countries.find(
    (country) => country.alpha2 === product.origin_country
  )
  const countryOption = country
    ? { label: country.name, value: country.alpha2 }
    : null

  return {
    dimensions: {
      weight: product.weight,
      width: product.width,
      height: product.height,
      length: product.length,
    },
    customs: {
      mid_code: product.mid_code,
      hs_code: product.hs_code,
      origin_country: countryOption,
      other_attrs: product.metadata ? Object.entries(product.metadata).map((attr) => ({
        title: attr[0],
        value: attr[1]
      })) : []  // * Custom field
    },
  }
}

export default AttributeModal
