import clsx from "clsx"
import React, { useState } from "react"
import useEditStoreActions from "./detail_hooks"
import ThumbnailModal from "./detail-thumbnail-modal"
import useToggleState from "../../hooks/use-toggle-state"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import Button from "../../components/fundamentals/button"
import Section from "../../components/organisms/section"
import { ExtendedStore } from "./details"

type Props = {
    store: ExtendedStore
}

const ThumbnailSection = (props: Props) => {
    const store = props.store;

    if (!store) {
        return <div>Loading...</div>
    }

    const { onUpdate } = useEditStoreActions()
    const { state, toggle, close } = useToggleState()

    const [isDeleting, setIsDeleting] = useState(false)

    const notification = useNotification()

    const handleDelete = () => {
        setIsDeleting(true)
        onUpdate(
            {
                // @ts-ignore
                images: [],
            },
            {
                onSuccess: () => {
                    notification("Success", "Successfully deleted image", "success")
                },
                onError: (err) => {
                    notification("Error", getErrorMessage(err), "error")
                },
            }
        )
        setIsDeleting(false)
    }

    return (
        <>
            <Section
                title="Shop Images"
                className="mt-base"
                customActions={
                    <div className="flex items-center gap-x-xsmall">
                        <Button
                            variant="secondary"
                            size="small"
                            type="button"
                            onClick={toggle}
                        >
                            {(store.images && Array.isArray(store.images) && store.images?.length > 0) ? "Edit" : "Upload"}
                        </Button>
                        {(store.images && Array.isArray(store.images) && store.images?.length > 0) && (
                            <Button
                                variant="secondary"
                                size="small"
                                type="button"
                                onClick={handleDelete}
                                loading={isDeleting}
                            >
                                Delete all
                            </Button>
                        )}
                    </div>
                }
            >
                <div
                    className={clsx("grid grid-cols-2 gap-x-6 mt-base", {
                        hidden: !store?.images,
                    })}
                >
                    {store.images && store.images.map((image, idx) => (
                        <div className="aspect-square p-4" key={idx}>
                            <div className="inter-small-semibold text-grey-50 items-center mb-base">#{idx + 1}</div>
                            <div>
                                <span className="inter-small-semibold text-grey-50 items-center">Title: </span>
                                <span>{image.title}</span>
                            </div>
                            <div>
                                <span className="inter-small-semibold text-grey-50 items-center">Description: </span>
                                <span>{image.description}</span>
                            </div>
                            <div>
                                <span className="inter-small-semibold text-grey-50 items-center">Button Text: </span>
                                <span>{image.button.text}</span>
                            </div>
                            <div>
                                <span className="inter-small-semibold text-grey-50 items-center">Button Redirect To: </span>
                                <span>{image.button.href}</span>
                            </div>
                            <div>
                                <div className="inter-small-semibold text-grey-50 items-center mt-base">
                                    PC Image
                                </div>
                                <img
                                    src={image.pc_image_url}
                                    alt={`PC Thumbnail ${idx + 1}`}
                                    className="object-contain rounded-rounded max-w-full max-h-full"
                                />
                            </div>
                            <div>
                                <div className="inter-small-semibold text-grey-50 items-center mt-base">
                                    Mobile Image
                                </div>
                                <img
                                    src={image.mobile_image_url}
                                    alt={`Mobile Thumbnail ${idx + 1}`}
                                    className="object-contain rounded-rounded max-w-full max-h-full"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Section>

            <ThumbnailModal store={store} open={state} onClose={close} />
        </>
    )
}

export default ThumbnailSection
