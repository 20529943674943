import React from "react"
import * as RadixAvatar from "@radix-ui/react-avatar"
import clsx from "clsx"

type LogoProps = {
    image: string;
    font?: string
    color?: string
}

const LogoImage: React.FC<LogoProps> = ({
    image,
    font = "inter-small-semibold",
    color = "bg-violet-60",
}) => {
    return (
        <RadixAvatar.Root
            className={clsx(
                "w-full h-full items-center justify-center overflow-hidden select-none rounded-circle",
                color
            )}
        >
            <RadixAvatar.Image
                src={image}
                alt="Logo Image"
                className="w-full h-full object-cover rounded-circle"
            />
            <RadixAvatar.Fallback
                className={clsx(
                    "w-full h-full flex items-center justify-center bg-inherit text-grey-0 rounded-circle",
                    font
                )}
            >
                LOGO
            </RadixAvatar.Fallback>
        </RadixAvatar.Root>
    )
}

export default LogoImage
