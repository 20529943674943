import React from "react"
import InputField from "../../../../components/molecules/input"
import FormValidator from "../../../../utils/form-validator"
import { NestedForm } from "../../../../utils/nested-form"

export type DimensionsFormType = {
  length: number | null
  width: number | null
  height: number | null
  weight: number | null
}

type DimensionsFormProps = {
  form: NestedForm<DimensionsFormType>
}

/**
 * Re-usable nested form used to submit dimensions information for products and their variants.
 * @example
 * <DimensionsForm form={nestedForm(form, "dimensions")} />
 */
const DimensionsForm = ({ form }: DimensionsFormProps) => {
  const {
    register,
    path,
    formState: { errors },
  } = form

  return (
    <div>
      <p className="inter-base-regular text-orange-600 mb-small">
        商品的尺寸(長寬高)與重量會影響宅配物流的運費，請特別留意!
        &nbsp;&nbsp;&nbsp;👉&nbsp;
        <ins>
          <a href="https://www.ecpay.com.tw/IntroTransport/Service_Fee" target="blank">綠界物流費用總覽</a>
        </ins>
      </p>
      <div className="grid grid-cols-4 gap-x-large">
        <InputField
          label="Width (mm)"
          placeholder="100..."
          type="number"
          {...register(path("width"), {
            min: FormValidator.nonNegativeNumberRule("Width"),
            valueAsNumber: true,
          })}
          errors={errors}
        />
        <InputField
          label="Length (mm)"
          placeholder="100..."
          type="number"
          {...register(path("length"), {
            min: FormValidator.nonNegativeNumberRule("Length"),
            valueAsNumber: true,
          })}
          errors={errors}
        />
        <InputField
          label="Height (mm)"
          placeholder="100..."
          type="number"
          {...register(path("height"), {
            min: FormValidator.nonNegativeNumberRule("Height"),
            valueAsNumber: true,
          })}
          errors={errors}
        />
        <InputField
          label="Weight (g)"
          placeholder="100..."
          type="number"
          {...register(path("weight"), {
            min: FormValidator.nonNegativeNumberRule("Weight"),
            valueAsNumber: true,
          })}
          errors={errors}
        />
      </div>
    </div>

  )
}

export default DimensionsForm
