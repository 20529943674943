import {
    AdminPostStoreReq
} from "@medusajs/medusa"
import {
    useAdminUpdateStore
} from "medusa-react"
import useImperativeDialog from "../../hooks/use-imperative-dialog"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"

const useEditStoreActions = () => {
    const dialog = useImperativeDialog()
    const notification = useNotification()

    const updateStore = useAdminUpdateStore()

    const onUpdate = (
        payload: Partial<AdminPostStoreReq>,
        onSuccess: () => void,
        successMessage = "Store was successfully updated"
    ) => {
        updateStore.mutate(
            // @ts-ignore TODO fix images being required
            payload,
            {
                onSuccess: () => {
                    notification("Success", successMessage, "success")
                    onSuccess()
                },
                onError: (err) => {
                    notification("Error", getErrorMessage(err), "error")
                },
            }
        )
    }

    return {
        onUpdate,
        updating: updateStore.isLoading,
    }
}

export default useEditStoreActions
